#orders-card{
    margin: 2rem 0;
    border-radius: 0.5rem;
    overflow: hidden;
}
.card.card-myorders{
    color: #12263f;
    font-size: .8rem;
    margin-bottom: 0;
    border: 0;
    border-bottom: 1px solid #edf2f9;
}
.card-myorders .btn-link{
    color: #12263f;
    margin-top: 0;
    text-align: right;
    font-size: .8rem;
}
.card-myorders .order-icon-payment svg{
    font-size: 1.2rem;
    color: #95aac9;
}
.order-eventdetail{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.order-card-header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: white !important;
}

.order-card-header .text-muted svg{
    color: #d9e7ef !important;
}
.card-myorders .collapse, .card-myorders .collapsing{
    background: #f7fbff;
    border-top: 1px solid #edf2f9;
}