@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  font-family: Lato;
}

body {
  color: #6e84a3 !important;
  background-color: #f8f9fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

.main-container {
  min-height: calc((100vh) - 340px);
}

#header .navbar-nav {
  margin-left: auto;
}

#header li {
  float: left;
  text-align: right;
}

#header li a {
  color: #e9f2f2;
}

.register {
  text-decoration: none !important;
}

.drop-link {
  text-decoration: none !important;
}

.drop-link:hover,
.drop-link.active {
  color: white !important;
}

#header li:first-child a {
  text-decoration: underline;
}

#header #create-event {
  color: white;
  float: right;
  border: 2px solid;
}

/* FORMS */
.select-search {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.select-search.is-disabled {
  opacity: 0.5;
}

.select-search__value {
  position: relative;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 8px;
  top: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-right: 2px solid #495057;
  border-bottom: 2px solid #495057;
  pointer-events: none;
}

.select-search.is-invalid > div > .select-search__input {
  border-color: red;
}

.select-search__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search__input:hover {
  border-color: #007bff;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  height: 180px;
  overflow: auto;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #007bff;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #007bff;
  color: white !important;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #007bff;
  color: #fff;
}

.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

.detail-event {
  gap: 32px;
}

#main-banner {
  height: 400px;
  background: black;
  overflow: hidden;
}

.banner-short {
  border-radius: 13px !important;
}

@media (min-width: 501px) and (max-width: 768px) {
  #main-banner {
    height: 655px;
  }

  #banner-content {
    display: grid;
    height: 655px !important;
  }

  #banner-img {
    height: 350px !important;
  }
}

@media (max-width: 500px) {
  #main-banner {
    height: 495px;
  }

  .main-banner-zig {
    height: 450px !important;
  }

  .banner-short {
    height: 495px !important;
  }

  .banner-short-zig {
    height: 450px !important;
  }
}

@media (max-width: 375px) {
  #main-banner {
    height: 470px;
  }

  .main-banner-zig {
    height: 420px !important;
  }

  .banner-short {
    height: 470px !important;
  }

  .banner-short-zig {
    height: 420px !important;
  }
}

@media (max-width: 320px) {
  #main-banner {
    height: 475px;
  }

  .main-banner-zig {
    height: 425px !important;
  }

  .banner-short {
    height: 475px !important;
  }

  .banner-short-zig {
    height: 425px !important;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  #main-banner {
    height: 635px;
  }

  #banner-content {
    display: grid;
    height: 635px !important;
  }

  #banner-img {
    height: 300px !important;
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  #banner-img {
    width: 67% !important;
  }

  .banner-infos {
    width: 33% !important;
  }

  .banner-infos h2 {
    font-size: 18px !important;
  }

  .banner-date {
    font-size: 16px !important;
  }
}

#main-banner #banner-background {
  width: 120%;
  margin-left: -10%;
  opacity: 0.6;
  height: 470px;
  filter: blur(15px);
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
  -webkit-filter: blur(15px);
}

#banner-content {
  height: 350px;
  border-radius: 8px;
  position: relative;
  padding: 0px !important;
  overflow: hidden;
  background: white;
}

#banner-img {
  background-size: cover;
  width: 70%;
  height: 100%;
  position: relative;
  background-position: center;
  float: left;
}

.banner-infos {
  position: relative;
  padding: 20px;
  width: 30%;
  height: 350px;
  float: right;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column;
  color: #98afbc;
  background-color: #ffffff;
}

.carousel-indicators {
  display: none;
}

.banner-infos span {
  display: block;
}

.banner-infos h2 {
  color: #12263f !important;
}

.banner-infos .banner-date {
  font-size: 1.2rem;
  color: #12263f;
}

.banner-infos .banner-place {
  line-height: 1.5rem;
  font-size: 0.85rem;
  word-break: break-word;
}

.banner-infos .banner-place i {
  font-size: 1rem;
}

.banner-infos button {
  border-radius: 10px;
  color: white;
  font-size: 16px;
}

.banner-infos button i {
  margin-right: 10px;
}

.banner-infos .share {
  background: white;
  border-radius: 34px;
  top: 35px;
  height: 45px;
  width: auto;
  margin: auto 15px 0px 15px;
  text-align: center;
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 5px 10px 3px rgba(186, 202, 211, 0.4);
}

.banner-infos .share a {
  background: #98afbc;
  border-radius: 30px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-infos .share i {
  color: white;
  display: inline-block;
  font-size: 14px;
}

@media (max-width: 768px) {
  #main-banner #banner-background {
    display: none;
  }

  #footer,
  #footer ul li,
  #footer .col-md-3 {
    text-align: center !important;
    justify-content: center;
  }

  .details ul {
    min-height: auto !important;
  }

  #banner-content {
    border-radius: 0 !important;
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    background-color: #f8f9fa;
  }

  .banner-infos {
    height: 120px;
    padding: 5px 15px 0 15px;
    width: 100%;
    color: #98afbc;
  }

  #main-banner {
    padding: 0;
    background-color: #f8f9fa;
    box-shadow: 0px 16px 32px 0px #00000014;
  }

  #main-banner #banner-img {
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  }

  .banner-infos {
    padding: 20px;
    gap: 16px;
    background-color: #ffffff;
    height: auto;
  }

  .banner-infos .name-date {
    height: auto;
    gap: 8px;
  }

  .banner-infos .interaction {
    gap: 4px;
  }

  .banner-infos .info {
    gap: 8px;
  }

  .banner-infos h2 {
    font-size: 25px;
  }

  .banner-infos .banner-date {
    font-size: 1rem;
  }

  .banner-infos .banner-place {
    font-size: 0.85rem;
    display: inline !important;
  }

  .banner-infos .banner-age {
    display: none !important;
  }

  .banner-infos button {
    font-size: 16px;
    line-height: 16px;
  }

  .banner-infos button i {
    margin-right: 0px;
  }
}

.container {
  position: relative;
  height: auto;
}

#main-bar .dropdown {
  width: 50%;
  float: right;
}

#main-bar .dropdown button {
  background: none;
  border: none;
}

#main-events,
#near-events {
  margin: 20px auto;
  text-align: center;
}

#main-events h2,
#near-events h2 {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

#main-events h2 {
  text-align: center;
}

#near-events {
  min-height: 600px;
}

.near-events-searched {
  margin: 20px auto;
  text-align: start;
}

.events-list {
  margin-top: 10px;
}

.events-list .event-list-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.event-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 16px 64px -16px rgb(46 55 77 / 10%);
  height: 315px;
  width: 260px;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  background: white;
  transition-duration: 0.2s;
}

@media (max-width: 768px) {
  .event-card {
    width: 240px;
  }
}

.event-card:hover {
  -webkit-box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
  box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
  margin-top: 2px;
}

.event-card .event-img {
  height: 210px;
  /* background: url(../img/banner2.jpg); */
  background-size: cover;
}

.event-card .event-actions {
  position: absolute;
  right: 10px;
  top: 155px;
}

.event-card .event-actions a {
  text-decoration: none;
  background: white;
  color: #0050c3;
  border-radius: 50px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin: 5px;
  float: left;
  position: relative;
  -webkit-box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
  box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
}

.event-date {
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 8px;
  color: white;
  box-shadow: 0px 7px 10px 0px #0000004f;
  width: 70px;
  height: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s ease;
}

.event-date .day {
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
  color: white;
}

.event-date .month {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: lighter;
  line-height: 20px;
}

.event-card .event-name {
  font-size: 1rem;
  line-height: initial;
  display: block;
}

.event-card .event-place {
  font-size: 15px;
  font-weight: lighter;
  display: block;
}

.event-card .event-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
  height: 90px;
  color: #98afbc;
  text-align: left;
}

.event-card .event-footer .banner-place {
  font-size: 0.8rem;
}

#near-events #more-events {
  border-radius: 50px;
  padding: 15px 30px;
  color: white;
  margin-top: 20px;
}

#event-content {
  margin: 40px auto;
}

#footer {
  color: #e9f2f2;
  width: 100%;
  min-height: 70px;
  font-size: 14px;
  padding: 30px 100px 10px 100px;
  bottom: 0px;
  position: relative;
}

#footer h2 {
  color: white;
  margin-top: 0px;
  font-size: 16px;
}

#footer ul {
  padding-left: 0px;
}

#footer ul li {
  list-style: none;
}

#footer a {
  color: #e9f2f2;
  display: block;
}

#footer-logo {
  width: 90px;
  margin-bottom: 20px;
}

#footer hr {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* .footer-tel {
  text-align: right;
  letter-spacing: 0;
  font-size: 24px;
  margin-bottom: -8px;
}
.footer-address {
  text-align: right;
  font: 'Light' 13px/21px 'Roboto';
  letter-spacing: 0;
  color: #FFFFFF !important;
  opacity: 1 !important;
  font-size: 13px;
}
.footer-tel span {
  font-size: 17px !important;
} */

#event-content #event-description {
  max-width: 100% !important;
  overflow: auto;
  /* height: auto !important; */
}

#event-content #event-description #event-header,
#event-content #event-description hr {
  display: none;
}

#event-content #event-description p,
#event-content #event-description p span {
  font-size: 0.9rem !important;
  word-break: break-word;
}

#event-content #event-description img {
  max-width: 100%;
  height: auto;
}

.checkout {
  margin-top: 40px;
}

#spiner-loading {
  left: 50%;
  top: 50%;
  position: absolute;
  margin-left: -40px;
  /* -1/2 width */
  margin-top: -40px;
  /* -1/2 height */
}

#drop-down {
  color: #e9f2f2 !important;
  font-size: 14px;
  margin-top: 6px;
  border: none;
}

button:focus {
  outline: none !important;
}

.discount-container {
  background-color: #dee9e9 !important;
  border: none !important;
  display: flex;
  direction: row;
  padding: 0px 20px 20px 20px;
}

.discount-btn {
  margin-left: 5px;
}

.discount-coupon-applied {
  color: #008656;
}

.change-cupom {
  margin-top: 15px;
  width: 90%;
}

.strong {
  font-weight: bolder;
}

.btn-login {
  background-color: transparent;
  border: none;
  color: blue;
  font-size: 14px;
  font-weight: bold;
}

.nav-link-btn {
  border-radius: 10px !important;
  color: white !important;
  font-size: 16px !important;
  margin-top: 40px !important;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  display: inline-block;
  font-weight: 400;
  text-decoration: none !important;
  position: absolute;
  bottom: 20px;
}

.banner-infos .nav-link-btn {
  border-radius: 10rem !important;
  padding: 0.35rem 1.5rem;
  font-size: 0.9rem !important;
}

.ticket-icon {
  margin-right: 10px;
}

.loading-payment {
  z-index: 99999;
  position: absolute;
  min-width: 100% !important;
  min-height: 720px !important;
  background: #e9f2f2;
}

.loading-container {
  min-width: 100% !important;
  min-height: 720px !important;
  background: #e9f2f2;
}

/*CSS da pagina meus tickets */

/* CSS de dispositivos mobile  */

@media (max-width: 2000px) {
  .loading-container {
    min-width: 100% !important;
    min-height: 720px !important;
    background: #e9f2f2;
  }
}

@media (max-width: 1000px) {
  #banner-img {
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    background-position: center;
    float: left;
  }
}

@media (min-width: 992px) {
  .banner-infos .share {
    padding: 0px 30px;
  }
}

@media (max-width: 991px) {
  #event-content #event-description {
    order: 1;
  }
}

@media (max-width: 768px) {
  #event-description {
    padding: 0;
  }

  .period {
    margin: 10px 0;
  }

  .cupom-button:hover {
    text-decoration: none !important;
    color: #12263f !important;
    border-color: transparent;
  }

  .content {
    padding: 0px 24px;
    gap: 32px;
  }
}

@media (max-width: 400px) {
  .header-list {
    float: none;
    text-align: left !important;
    color: #e9f2f2 !important;
  }

  .location-modal .modal-header {
    padding: 1rem;
  }

  .modal-flow {
    padding: 0.3rem !important;
  }

  .mobile {
    padding: 0.3rem !important;
  }

  .location-modal .new-modal-title {
    padding: 0.2rem !important;
  }
}

@media (max-width: 400px) {
  /* .footer-tel {
    font-size: 21px;
    margin-bottom: 4px;
  } */
  .loading-container {
    min-width: 100% !important;
    min-height: 600px !important;
    background: #e9f2f2;
  }
}

@media (max-width: 500px) {
  /*banner && caroussel */
  #banner-content {
    display: flex;
    flex-direction: column;
  }

  #footer {
    padding: 30px 30px 10px 30px;
  }

  #footer-logo {
    width: 50px;
  }

  .interaction {
    display: none !important;
  }

  #banner-img {
    width: 100%;
    height: 40%;
    background-position: center;
    background-color: #000000;
    background-repeat: no-repeat;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .banner-infos h2 {
    font-size: 18px !important;
  }

  .banner-infos span {
    display: block;
  }

  .banner-infos .banner-date {
    font-size: 15px;
  }

  .banner-infos .banner-place {
    font-size: 13px !important;
  }

  #place-ellips-none {
    max-width: 160px;
    display: block;
    line-height: 15px;
    text-wrap: pretty;
  }

  .banner-infos button {
    border-radius: 10px;
    color: white;

    font-size: 16px;
  }

  .banner-infos .nav-link-btn {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }

  /*cards main */
  .event-card {
    background: white;
    position: relative;
    overflow: hidden;
    float: left;
    border-radius: 8px;
    background-position: top;
    -webkit-box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.1);
    box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.1);
    max-height: 110px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: ease;
    transition-duration: 0.2s;
  }

  .event-card:hover {
    -webkit-box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
    box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
    margin-top: 2px;
  }

  .event-card .event-img {
    height: 80px;
    width: 80px;
    margin-top: 16px;
    max-width: 100px;
    max-height: 100px;
    margin-left: 80px;
    border-radius: 50%;
    background-size: cover;
  }

  .event-card .event-actions {
    position: absolute;
    right: 10px;
    top: 155px;
    display: none;
  }

  .event-card .event-actions a {
    text-decoration: none;
    background: white;
    color: #0050c3;
    border-radius: 50px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin: 5px;
    float: left;
    position: relative;
    -webkit-box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
    box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
  }

  .event-card:hover .event-date {
    background: #0050c3;
  }

  .event-card .day {
    font-size: 25px;
    line-height: 60px;
    font-weight: 700;
    color: white;
    margin-top: -5px;
  }

  .event-card .month {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: lighter;
    line-height: 10px;
    margin-left: 0px;
    margin-top: -5px;
  }

  .event-card .event-name {
    max-height: 200px;
    margin-top: 12px;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event-card .event-place {
    font-size: 15px;
    font-weight: lighter;
    display: block;
  }

  .event-card .event-footer {
    padding: 6px 2px 2px 6px;
    position: absolute;
    top: 0px;
    height: 100px;
    width: calc(100% - 170px);
    margin-left: 170px;
    left: 0;
    background: transparent;
    color: #98afbc;
    text-align: left;
  }

  .event-footer .banner-date {
    display: none;
  }

  .event-footer .footer-info-mobile {
    margin-top: -20px;
  }

  .event-footer .footer-info-mobile .banner-place {
    position: absolute;
    bottom: 7px;
    max-width: 160px;
    word-wrap: break-word;
  }

  .neighbord {
    display: none;
  }
}

/* TERMS */
.terms h1 {
  font-size: 1.5rem;
}

.terms p,
.terms h4 {
  font-size: 0.8rem;
  width: 100%;
}

/* CUSTOM */
.alert-primary {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.alert-secondary {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3;
}

.alert-success {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e;
}

.alert-danger {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757;
}

.alert-warning {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343;
}

.alert-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}

.alert-light {
  color: #283e59;
  background-color: #edf2f9;
  border-color: #edf2f9;
}

.alert-dark {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f;
}

/* CUSTOM */
.card {
  margin-bottom: 1.5rem;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.badge {
  padding: 0.33em 0.5em;
  font-size: 76%;
  font-weight: 400;
  border-radius: 0.375rem;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-soft-primary {
  color: #2c7be5;
  background-color: #d5e5fa;
}

.badge-soft-secondary {
  color: #6e84a3;
  background-color: #e2e6ed;
}

.badge-soft-success {
  color: #00d97e;
  background-color: #ccf7e5;
}

.badge-soft-info {
  color: #39afd1;
  background-color: #d7eff6;
}

.badge-soft-warning {
  color: #f6c343;
  background-color: #fdf3d9;
}

.badge-soft-danger {
  color: #e63757;
  background-color: #fad7dd;
}

.badge-soft-light {
  color: #edf2f9;
  background-color: #fbfcfe;
}

.badge-soft-dark {
  color: #12263f;
  background-color: #d0d4d9;
}

.cupom-button {
  padding: 10px 0px;
  background-color: #dee9e9 !important;
  border: 1px solid #dee9e9;
  border-radius: 0px !important;
  color: #12263f;
}

.cupom-button:hover {
  text-decoration: underline;
  color: #12263f;
  border-color: transparent;
}

.cupom-button:active {
  text-decoration: underline;
  color: #12263f;
  border-color: transparent;
}

.cupom-button:focus {
  border-color: transparent;
  color: #12263f;
}

.fadeIn {
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.react-inputs-validation__msg_identifier {
  color: #dc3545 !important;
  font-size: 15px;
  margin-top: -3px;
}

.error-message {
  color: #dc3545 !important;
  font-size: 15px;
  margin-top: 0px;
}

.bt-checkout:disabled {
  background-color: #98afbc !important;
}

.location-modal .new-modal-title {
  font-weight: 600;
  font-size: 1.25rem !important;
  color: #12263f !important;
}

.location-modal .modal-header {
  border: none;
}

.location-modal .close-icon {
  font-size: 1.5rem;
}

.location-search {
  border: 1px solid #dee2e8;
  border-radius: 0.25rem;
}

.location-search input {
  border: none !important;
  font-size: 1rem;
}

.swal2-title {
  font-size: 1.5rem !important;
  color: #12263f !important;
}

.swal2-content {
  color: #6e84a3 !important;
}

.swal2-icon {
  color: #6e84a3 !important;
}
