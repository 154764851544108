p {
  margin: 0px;
}

.popup-modal {
  display: static;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin: 0;
  border: none;
  z-index: 100000;
  background-color: #ffffff;
  border-radius: 4px 4px 0 0;
}

.popup-modal .popup-modal-content {
  margin: 20px 0;
  padding: 0 16px;
}

.popup-modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  z-index: 100000;
}

.zig-icon {
  width: 5rem;
  height: 5rem;
}

.download-title {
  font-weight: 700;
  font-size: 1.125rem;
  max-width: 15.938rem;
  line-height: 1.688rem;
  color: #17171a;
  text-align: center;
}

.download-quantity-text,
.arguments-text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #3a3940;
}
