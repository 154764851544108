.user-header {
  margin-top: 40px;
}

.cem {
  width: 100%;
}

.cem i {
  margin-right: 10px;
}

@media (max-width: 400px) {
  .user-header {
    margin-top: 20px;
  }
}

div.form-group label {
  display: block;
  color: #485460;
  font-size: 12.8px;
  font-weight: 700;
  line-height: 19.2px;
}

div.form-group input {
  padding: 11px, 13px, 12px, 13px;
  background-color: #ecf2f7;
  border-radius: 8px;
  border: 1px solid #ecf2f7;
}

div.form-group button.edit-button {
  margin-top: 1.9rem;
}

.edit-button {
  color: white;
  font-size: 16px;
  line-height: 24px;
  background-color: #5532e0;
  padding: 13px, 188.91px, 13px, 189.09px;
}

.edit-button:hover {
  color: white;
  background-color: #0050C3;
}

nav.nav-tabs a {
  font-weight: 400;
  font-size: 24px;
  line-height: 38.4px;
  color: #98afbc;
  border: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
}

nav.nav-tabs a.active {
  color: #0050C3 !important;
  font-weight: 500;
  border-bottom: 4px solid #0050C3 !important;
}